import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Flex, Button, Stack, Heading, useTheme } from '@chakra-ui/react'

import {
  Article,
  Section,
  Banner,
  Hero,
  HeroBody,
  HeroSubtitle,
  HeroCta,
  RowContent,
  ColImage,
  ColInfo,
  P,
  FeaturedBanner,
  Carousel,
  Gallery,
  ColContent,
  Image,
  FeatureList,
  FeatureItem,
  HeroTitle,
  H3,
} from '../ui'

import Layout from '../components/layout'
import FormContact from '../components/formContact'
import ButtonBook from '../components/buttonBook'
import { AiFillCheckCircle } from 'react-icons/ai'
import { ImWhatsapp } from 'react-icons/im'
import { Checklist } from '../components/landingPage'

function ChecklistCTA() {
  return (
    <Checklist
      title="Elije tu terreno ideal"
      subtitle="Además de todos los beneficios al conseguir tu terreno puedes elegir entre una gran cantidad de unidades dentro de Hakuna Matata, así podrás elegir el que más te convenga."
      list={[
        'Puedes elegir tu terreno por tamaño, desde 700 m2 a 2,000 m2',
        'Por ubicación, dentro de cualquiera de las tres etapas',
        'Por tipo, contamos con terrenos estándar y terrenos premium',
      ]}
    >
      <ButtonBook width="100%" maxWidth="460px" />
    </Checklist>
  )
}

function IndexPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [bookSession, setBookSession] = useState('')

  return (
    <Layout title={t('index:title')}>
      <Article></Article>
      <Hero
        image={[
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: '0.85' }}
          />,
        ]}
      >
        <HeroBody minHeight={{ md: '600px' }} paddingY={8}>
          <HeroTitle textAlign="center" maxWidth={{ md: '460px' }}>
            Construye la Casa de tus Sueños sin Gastar una Fortuna
          </HeroTitle>
          <HeroSubtitle textAlign="center" maxWidth={{ md: '460px' }}>
            En Hakuna Matata encontrarás la mejor opción para conseguir tu terreno de forma rápida,
            segura y con facilidades de pago.
          </HeroSubtitle>
          <Flex padding={2} border="2px solid white" color="white" width="100%" maxWidth="460px">
            <P
              fontWeight="bold"
              display="flex"
              flexDirection="column"
              fontSize="2xl"
              textAlign="center"
              width="100%"
              textShadow="0px 0px 5px black"
            >
              APARTA EL TUYO DESDE $10,000
            </P>
          </Flex>
          <HeroCta display="flex" width="100%" maxWidth="460px" flexDirection="column">
            <ButtonBook width="100%" pointerEvents="all" size="lg" />
          </HeroCta>
        </HeroBody>
      </Hero>

      <Article>
        <Section>
          <RowContent>
            <ColImage
              image={<Image fixed={data.desc.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo
              title="Descubre lo fácil que es obtener un terreno ecológico con amenidades de lujo en Cancún"
              subtitle={
                <Stack spacing={4}>
                  <P>
                    Hakuna Matata fue creado con el propósito de ofrecer la mayor calidad en
                    privacidad, seguridad, los mejores precios, amenidades de lujo para todos los
                    gustos y una plusvalía garantizada.
                  </P>
                  <P>ESTOS TERRENOS SON IDEALES PARA:</P>
                  <P fontWeight="bold">Personas que interesadas en su futuro</P>
                  <P>
                    Eres la persona que busca crear un patrimonio para ti y para tu familia;
                    construir la casa de tus sueños y tener todas las comodidades que puedas
                    necesitar.
                  </P>
                  <P fontWeight="bold">Personas que son inversionistas</P>
                  <P>
                    Siempre buscando oportunidades para hacer crecer tu dinero. Deseas una excelente
                    inversión a mediano plazo, para construcción, Airbnb o para rentas vacacionales.
                  </P>
                </Stack>
              }
            />
          </RowContent>
        </Section>

        <ChecklistCTA />

        <Section>
          <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            <Box width="100%" flex="2/3">
              <Gallery
                height="100%"
                paddingBottom="0"
                marginBottom="0"
                images={data.gallery.edges.map((image) => (
                  <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
                ))}
                buttonText="Ver fotos"
              />
            </Box>
            <Box marginLeft={{ lg: 4 }} marginTop={{ base: 4, lg: 0 }} flex="1/3">
              <Stack
                spacing={3}
                padding={4}
                boxShadow="0px 0px 10px rgba(0,0,0,0.15)"
                borderRadius="15px"
              >
                <H3>Amenidades</H3>
                {[
                  'Cenote artificial',
                  'Piscinas',
                  'Biopiscinas',
                  'Toboganes',
                  'Seguridad las 24/7',
                  'Barda perimetral',
                  'Palapas',
                  'Áreas para acampar',
                  'Ciclopista',
                  'Parque infantil',
                  'Restaurante',
                  'Huertos',
                  'Gimnasio al aire libre',
                  'Canchas multiusos',
                  'Parques para mascotas',
                ].map((amenity) => (
                  <P key={v4()} display="flex" alignItems="center">
                    <AiFillCheckCircle
                      size="1.25rem"
                      style={{
                        marginRight: '5px',
                        color: 'green',
                        backgroundColor: 'white',
                        borderRadius: '100px',
                        flexShrink: 0,
                      }}
                    />
                    {amenity}
                  </P>
                ))}
                <ButtonBook width="100%" maxWidth="460px" size="lg" />
              </Stack>
            </Box>
          </Flex>
        </Section>

        <Section
          title="¿Por qué deberías adquirir tu terreno en Hakuna Matata?"
          subtitle="En Hakuna Matata nos esforzamos para crear uno de los mejores desarrollos de su tipo en Cancún, de esta forma podemos ofrecer beneficios únicos que no obtendrás en ningún otro lugar."
          titleAlign="center"
          spacing={10}
        >
          <RowContent>
            <ColImage
              image={<Image fixed={data.desc1.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo
              title="Precios y plan de financiamiento que se adapta a tu bolsillo"
              subtitle="Contamos con precios competitivos, financiamiento sin intereses y con cómodos plazos mensuales que se adaptan a tus necesidades."
            />
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo
              title="Las amenidades más completas de Cancún"
              subtitle="Hakuna Matata te ofrece las mejores amenidades de Cancún. Disfruta de cenotes artificiales, bio-piscinas, restaurantes, huertos, ciclo pistas, gimnasio al aire libre y todo lo que puedas necesitar."
            />
            <ColImage
              image={<Image fixed={data.desc2.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>

          <RowContent>
            <ColImage
              image={<Image fixed={data.desc3.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo
              title="Inversión garantizada"
              subtitle="Tu inversión está asegurada tan sólo con la Plusvalía que puede generar cada uno de los terrenos de Hakuna Matata, pero si estás pensando en construir o en rentas vacacionales, puedes incrementar todavía más tus utilidades."
            />
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo
              title="Terrenos 100% ecológicos"
              subtitle="Todos los terrenos son ecológicos por lo que puedes conectarte con la naturaleza y reducir el impacto ambiental, sin sacrificar ninguna comodidad."
            />
            <ColImage
              image={<Image fixed={data.desc4.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>
        </Section>

        <ChecklistCTA />

        <Section
          title="Certeza jurídica"
          subtitle="Todos nuestros contratos son notariados, por lo que puedes tener la certeza de que tu compra está protegida y que todo está en regla."
          titleAlign="center"
        >
          <Box>
            <Box maxWidth="450px" marginX="auto">
              <Image
                style={{ width: '100%', height: '100%', paddingBottom: '100%' }}
                fixed={data.traction.childImageSharp.fixed}
              />
            </Box>
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "landing/hero.jpg" }) {
      ...bannerSectionImage
    }

    hakuna: file(relativePath: { eq: "landing/hakuna.jpg" }) {
      ...fixedImage
    }

    desc: file(relativePath: { eq: "landing/desc.jpg" }) {
      ...fixedImage
    }

    desc1: file(relativePath: { eq: "landing/desc1.jpg" }) {
      ...fixedImage
    }

    desc2: file(relativePath: { eq: "landing/desc2.jpg" }) {
      ...fixedImage
    }

    desc3: file(relativePath: { eq: "landing/desc3.jpg" }) {
      ...fixedImage
    }

    desc4: file(relativePath: { eq: "landing/desc4.jpg" }) {
      ...fixedImage
    }

    traction: file(relativePath: { eq: "landing/traction.png" }) {
      ...fixedImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default IndexPage
